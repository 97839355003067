<template>
  <v-card flat>
    <v-card-title>Quy trình xử lý</v-card-title>

    <v-container class="px-1 pt-0 pb-5" fluid>
      <v-row class="mx-0" style="height: 100%">
        <!-- Start: Step Col -->
        <v-col class="col-wrap py-0" v-for="num in stepList.length" :key="num">
          <col-step
            :step="num"
            :step-list="stepList"
            :store-name="storeName"
            @showModalAddCommentHaveFiles="showModalAddCommentHaveFiles"
            @previewImage="previewImage"
          ></col-step>
        </v-col>
        <!-- End: Step col -->
      </v-row>
    </v-container>

    <modal-add-comment-have-files
      :store-name="storeName"
      :comment="comment"
      @previewImage="previewImage"
      @resetComment="resetComment"
    ></modal-add-comment-have-files>

    <modal-preview-image :url="selectedImage"></modal-preview-image>
  </v-card>
</template>

<script>
import ColStep from "./components/ColStep";
import ModalAddCommentHaveFiles from "./components/ModalAddCommentHaveFiles.vue";
import ModalPreviewImage from "@/core/components/modals/ModalProduct/components/ModalPreviewImage.vue";

export default {
  components: {
    ColStep,
    ModalPreviewImage,
    ModalAddCommentHaveFiles
  },
  props: {
    stepList: {
      type: Array
    },
    storeName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      comment: {
        off_biz_id: null,
        step: null,
        content: null,
        files: []
      },
      selectedImage: ""
    };
  },

  methods: {
    previewImage(url) {
      this.selectedImage = url;
      this.$modal.show({ name: "modal-preview-image" });
    },

    showModalAddCommentHaveFiles(value) {
      this.comment = value;

      this.$modal.show({
        name: "modal-add-comment-have-files"
      });
    },

    resetComment() {
      this.comment = {
        off_biz_id: null,
        step: null,
        content: null,
        files: []
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.col-wrap {
  border-right: 1px solid #e5e5e5;
  min-height: 100%;
  &:last-child {
    border-right: 0;
  }
}
</style>
