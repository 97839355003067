<template>
  <div class="d_flex col-main flex-column">
    <!-- Start: Step Title -->
    <div
      :class="offBizDetail.nextStep === step ? 'green lighten-5' : null"
      class="col-title rounded tp-border-thin pa-3"
    >
      <span
        :class="
          offBizDetail.nextStep === step ? 'green lighten-3' : 'grey lighten-3'
        "
        class="text-body-1 font-weight-medium col-step"
      >
        {{ step }}
      </span>
      <span class="font-weight-medium">{{ stepList[step - 1].name }}</span>
    </div>
    <!-- End: Step Title -->
    <!-- Start: Step Comments -->
    <div class="col-comments mt-2">
      <!-- Start: Comment Form -->
      <div v-if="offBizDetail.id" class="comment-form mt-2">
        <v-textarea
          v-model="comment.content"
          append-icon="mdi-send"
          prepend-inner-icon="mdi-paperclip"
          auto-grow
          background-color="grey lighten-4"
          class="text-body-1"
          dense
          flat
          hide-details
          placeholder="Viết bình luận"
          rows="1"
          solo
          :disabled="offBizDetail.nextStep !== step"
          :loading="loading"
          @click:append="createComment()"
          @click:prepend-inner="$refs.imagesInput.click()"
        ></v-textarea>

        <input
          type="file"
          ref="imagesInput"
          accept="image/*"
          style="display: none"
          multiple
          @change="handleFileUpload"
        />
      </div>
      <!-- End: Comment Form -->
      <!-- Start: Comment List -->
      <div
        v-for="item in commentStep"
        :key="item.id"
        class="col-comments--list"
      >
        <div class="d-flex comment-box grey lighten-4 rounded pa-2 mt-2">
          <v-avatar
            class="comment-box--avatar mr-2"
            color="deep-purple lighten-3"
            size="26"
          >
            <img
              v-if="false"
              :alt="item.created_by_user.name"
              :src="item.created_by_user.avatar"
            />
            <span class="white--text font-weight-black">{{
              item.created_by_user_name.length > 0
                ? item.created_by_user_name[0]
                : ""
            }}</span>
          </v-avatar>
          <div class="comment-box--main">
            <div class="font-weight-bold">
              {{ item.created_by_user_name }}
            </div>
            <div
              v-if="item.files && item.files.length"
              class="d-flex flex-wrap gap-1 mt-1"
            >
              <img
                v-for="(file, index) in item.files"
                :key="file + index"
                style="width: 48px; height: 48px;"
                :src="file"
                class="tp-border-thin grey lighten-3 flex-shrink-0 cursor-zoom-in"
                @click="$emit('previewImage', file)"
              />
            </div>
            <div class="comment-box--main__content">
              {{ item.content }}
            </div>
            <div
              class="grey--text text-subtitle-2 font-weight-light text--darken-2 mt-1"
            >
              {{ dateTimeFormat(item.time) }}
            </div>
          </div>
        </div>
      </div>
      <!-- End: Comment List -->
    </div>
    <!-- End: Step Comments -->
    <!-- Start: Step Action -->
    <div
      v-if="offBizDetail.id && offBizDetail.huy_status !== 1"
      class="d-flex justify-space-between col-actions mt-4"
    >
      <template v-if="offBizDetail.nextStep === step">
        <v-btn
          v-if="offBizDetail.nextStep > 1"
          :disabled="
            hasCancelOrSold() || (offBizDetail && offBizDetail.step === 4)
          "
          class="grey lighten-4 grey--text text--darken-2 rounded-lg"
          depressed
          @click="updateOffBiz(step - 1)"
          >Trở lại
        </v-btn>
        <v-btn
          v-if="offBizDetail.nextStep < stepList.length"
          :disabled="hasCancelOrSold()"
          class="rounded-lg"
          color="primary"
          dark
          depressed
          @click="nextStep(offBizDetail.nextStep)"
        >
          Xong
        </v-btn>
      </template>
    </div>
    <!-- End: Step Action -->
  </div>
</template>

<script>
import moment from "moment";
import MediaService from "@/core/service/media.service";
import { SELECTED_ACTIONS } from "../../../constants/constants";

export default {
  props: {
    step: {
      type: Number,
      required: true
    },
    stepList: {
      type: Array
    },
    storeName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      comment: {
        off_biz_id: null,
        step: null,
        content: null,
        files: []
      },
      loading: false
    };
  },
  computed: {
    offBizDetail() {
      return this.$store.getters[`${this.storeName}/offBizDetail`];
    },
    comments() {
      return this.$store.getters[`${this.storeName}/comments`];
    },
    commentStep() {
      return this.comments.filter(comment => comment.step === this.step);
    },
    statusRequest() {
      return this.$store.getters["INTERNAL_REPAIR_NOTE/statusRequest"];
    }
  },
  methods: {
    async createComment() {
      this.loading = true;
      this.comment.off_biz_id = this.offBizDetail.id;
      this.comment.step = this.step;
      // Create comment
      await this.$store.dispatch(`${this.storeName}/addComment`, this.comment);
      // Reset comment
      this.comment = {
        off_biz_id: null,
        step: null,
        content: null,
        files: []
      };
      this.loading = false;
    },

    async handleFileUpload(event) {
      this.loading = true;
      const files = event.target.files;
      if (!files || files.length === 0) return;

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
      }
      formData.append("folder", "cms_comment");

      this.$refs.imagesInput.value = null;
      try {
        const response = await MediaService.uploadMultipleImages(formData);
        this.comment.files = [...this.comment.files, ...response.data];
        this.comment.off_biz_id = this.offBizDetail.id;
        this.comment.step = this.step;

        this.$emit("showModalAddCommentHaveFiles", this.comment);

        this.comment = {
          off_biz_id: null,
          step: null,
          content: null,
          files: []
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    confirmUpdateLastStep(step) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Chuyển trạng thái sang xử lý xong</span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xong",
            onClickHandler: async () => {
              await this.updateOffBiz(step);
            }
          }
        }
      });
    },

    dateTimeFormat(seconds) {
      return moment.unix(seconds).format("DD/MM/YYYY HH:mm:ss");
    },

    hasCancelOrSold() {
      const { selected_action } = this.offBizDetail;
      if (
        selected_action === SELECTED_ACTIONS.cancel ||
        selected_action === SELECTED_ACTIONS.approval_for_sale
      ) {
        return true;
      }
      return false;
    },

    nextStep(step) {
      if (step === this.stepList.length - 1) {
        this.confirmUpdateLastStep(step + 1);
      } else {
        this.updateOffBiz(step + 1);
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.offBizDetail.nextStep = this.step - 1;
      }
    },
    async updateOffBiz(step) {
      // Request create
      // const { step } = this.offBizDetail;
      this.offBizDetail.step = step;
      this.offBizDetail.nextStep = step;
      // if (
      //     (selected_action === SELECTED_ACTIONS.tranfer ||
      //         selected_action === SELECTED_ACTIONS.approval_for_sale) &&
      //     step < this.stepList.length
      // ) {
      //   return;
      // }
      await this.$store.dispatch("INTERNAL_REPAIR_NOTE/updateOffBiz", {
        ...this.offBizDetail,
        selected_action: 1
      });
      // Alert
      if (this.statusRequest.value === "success-updateOffBiz") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 4px;
}
.col {
  &-main {
    height: 100%;
    overflow: hidden;
  }

  &-step {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    font-size: 0.75rem;
    justify-content: center;
    height: 24px;
    margin-right: 0.5rem;
    min-width: 24px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 24px;
  }

  &-actions {
    min-height: 36px;
  }
}

.comment-box {
  &--main {
    flex: 1 1 auto;
    min-width: 0;
    width: 100%;

    &__content {
      white-space: pre-line;
      word-break: break-word;
    }
  }
}
</style>
